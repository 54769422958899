import React from "react";
import { useTranslation } from "react-i18next";

const NewsEvents = () => {
  const { t, i18n } = useTranslation();
  return (
    <div className="newsEvents my-lg-4 mb-md-2 mb-sm-2 mb-2">
      <h5>
        {i18n.exists("UpdatedandEvents")
          ? t("UpdatedandEvents")
          : `Updated News & Events`}
      </h5>
      <div className="scrollingNews">
        <ul>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("newsOne")
              ? t("newsOne")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
          <li>
            {i18n.exists("news")
              ? t("news")
              : `'Har Ghar Jal' - Pouring strength into the life of every girl child`}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default NewsEvents;
