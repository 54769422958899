import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, ProgressBar } from "react-bootstrap";
import statesAndDistricts from "../../data/statesAndDistricts";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import axios from "axios";

const schema = yup.object().shape({
  state: yup.string().required("State is required"),
  district: yup.string().required("District is required"),
  email: yup
    .string()
    .required("Email is required")
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      "Please enter a valid email address"
    )
    .email("Email is invalid"),
  mobile: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[0-9]+$/, "Must enter only digits")
    .matches(/^[0-9]{10}$/, "Mobile number must be 10 digits"),
  fieldUser: yup
    .mixed()
    .test(
      "required",
      "Field user data template is required",
      (value) => value && value.length > 0
    ),
  rateMaster: yup
    .mixed()
    .test(
      "required",
      "Rate master data template is required",
      (value) => value && value.length > 0
    ),
  beniData: yup
    .mixed()
    .test(
      "required",
      "Beneficiary data template is required",
      (value) => value && value.length > 0
    ),
});

function OnboardingModel(props) {
  const [selectedState, setSelectedState] = useState("");
  const [districts, setDistricts] = useState([]);
  const { t, i18n } = useTranslation();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Add state for submitting

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields, isSubmitted },
    setValue,
    resetField,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setDistricts(statesAndDistricts[state] || []);
    setValue("state", state); // Set value to react-hook-form
    resetField("district"); // Reset district on state change
    trigger("state"); // Trigger re-validation for state
  };

  const handleDistrictChange = (e) => {
    const district = e.target.value;
    setValue("district", district); // Set value to react-hook-form
    trigger("district"); // Trigger re-validation for district
  };

  const getValidationClass = (name) => {
    if (errors[name]) return "error-border";
    if (touchedFields[name] || isSubmitted) return "success-border";
    return "";
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true); // Set submitting state to true
    setMessage(""); // Clear previous message
    const formData = new FormData();
    formData.append("state", data.state);
    formData.append("district", data.district);
    formData.append("email", data.email);
    formData.append("mobile", data.mobile);
    formData.append("fieldUser", data.fieldUser[0]);
    formData.append("rateMaster", data.rateMaster[0]);
    formData.append("beniData", data.beniData[0]);
    try {
      const response = await axios.post(
        "http://localhost:5000/upload/multiple",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            setUploadProgress(percentCompleted);
          },
        }
      );
      if (response.data.status === true) {
        setMessage("File uploaded successfully");
        setUploadProgress(0);
        props.isHideFrormHandler(true); // hide the frorm
      }
    } catch (error) {
      if (error.response) {
        setMessage(`Upload failed: ${error.response.data.error}`);
      } else {
        setMessage("Upload failed: An unknown error occurred");
      }
      setUploadProgress(0);
    } finally {
      setIsSubmitting(false); // Set submitting state to false
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal-dialog-onbd"
      backdrop="static"
    >
      <Modal.Header className="modal-header-onbd" closeButton>
        <Modal.Title>
          {i18n.exists("OnBoardingProcesstxt")
            ? t("OnBoardingProcesstxt")
            : `Onboarding Process`}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-onbd">
        <form
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          style={{ display: props.isHideFrorm ? "none" : "block" }}
        >
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("stateTxt") ? t("stateTxt") : `State`}
                </label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("state")}
                  onChange={handleStateChange}
                  value={selectedState}
                  className={getValidationClass("state")}
                >
                  <option value="">
                    {i18n.exists("SelectStateTxt")
                      ? t("SelectStateTxt")
                      : `Select State`}
                  </option>
                  {Object.keys(statesAndDistricts).map((state) => (
                    <option key={state} value={state}>
                      {state}
                    </option>
                  ))}
                </Form.Select>
                {errors.state && (
                  <p className="error-msg">{errors.state.message}</p>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("districtTxt") ? t("districtTxt") : `District`}
                </label>
                <Form.Select
                  aria-label="Default select example"
                  {...register("district")}
                  disabled={!selectedState}
                  onChange={handleDistrictChange}
                  className={getValidationClass("district")}
                >
                  <option value="">
                    {i18n.exists("SelectDistrictTxt")
                      ? t("SelectDistrictTxt")
                      : `Select District`}
                  </option>
                  {districts.map((district) => (
                    <option key={district} value={district}>
                      {district}
                    </option>
                  ))}
                </Form.Select>
                {errors.district && (
                  <p className="error-msg">{errors.district.message}</p>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("emailAddressTxt")
                    ? t("emailAddressTxt")
                    : `Email Address`}
                </label>
                <input
                  className={`form-control ${getValidationClass("email")}`}
                  type="text"
                  placeholder={
                    i18n.exists("emailPlaceholderTxt")
                      ? t("emailPlaceholderTxt")
                      : `Enter your email address`
                  }
                  {...register("email")}
                />
                {errors.email && (
                  <p className="error-msg">{errors.email.message}</p>
                )}
                <span className="icon-mail">
                  <i className="fa fa-envelope" aria-hidden="true"></i>
                </span>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("mobileNumberTxt")
                    ? t("mobileNumberTxt")
                    : `Mobile Number`}
                </label>
                <input
                  className={`form-control ${getValidationClass("mobile")}`}
                  type="text"
                  placeholder={
                    i18n.exists("mobileNumberPlaceholderTxt")
                      ? t("mobileNumberPlaceholderTxt")
                      : `Enter your mobile number`
                  }
                  {...register("mobile")}
                />
                {errors.mobile && (
                  <p className="error-msg">{errors.mobile.message}</p>
                )}
                <span className="icon-mob">
                  <i className="fa fa-mobile" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("fieldUserDataTxt")
                    ? t("fieldUserDataTxt")
                    : `Field User Data`}
                </label>
                <input
                  className={`form-control ${getValidationClass("fieldUser")}`}
                  type="file"
                  {...register("fieldUser")}
                  accept=".xlsx, .xls, .csv"
                />
                {errors.fieldUser && (
                  <p className="error-msg">{errors.fieldUser.message}</p>
                )}
                <a
                  className="btn-download"
                  href="/assets/data/field users template.xlsx"
                  download
                >
                  {i18n.exists("downloadTemplateTxt")
                    ? t("downloadTemplateTxt")
                    : `Download Template`}
                  <i
                    className="fa fa-download download-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("rateMasterDataTxt")
                    ? t("rateMasterDataTxt")
                    : `Rate Master Data`}
                </label>
                <input
                  className={`form-control ${getValidationClass("rateMaster")}`}
                  type="file"
                  {...register("rateMaster")}
                  accept=".xlsx, .xls, .csv"
                />
                {errors.rateMaster && (
                  <p className="error-msg">{errors.rateMaster.message}</p>
                )}
                <a
                  className="btn-download"
                  href="/assets/data/rate master template.xlsx"
                  download
                >
                  {i18n.exists("downloadTemplateTxt")
                    ? t("downloadTemplateTxt")
                    : `Download Template`}
                  <i
                    className="fa fa-download download-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="form-group-onbd mb-3 mb-lg-5">
                <label>
                  {i18n.exists("beneficiaryDataTxt")
                    ? t("beneficiaryDataTxt")
                    : `Beneficiary Data`}
                </label>
                <input
                  className={`form-control ${getValidationClass("beniData")}`}
                  type="file"
                  {...register("beniData")}
                  accept=".xlsx, .xls, .csv"
                />
                {errors.beniData && (
                  <p className="error-msg">{errors.beniData.message}</p>
                )}
                <a
                  className="btn-download"
                  href="/assets/data/Beneficiary Data Template.xlsx"
                  download
                >
                  {i18n.exists("downloadTemplateTxt")
                    ? t("downloadTemplateTxt")
                    : `Download Template`}
                  <i
                    className="fa fa-download download-icon"
                    aria-hidden="true"
                  ></i>
                </a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
              <Button
                type="submit"
                className="btn-submit btn btn-primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <>
                    <span>Submitting...</span>
                    <div className="spinner-border ms-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </>
                ) : (
                  <>
                    {i18n.exists("submitBtnTxt") ? t("submitBtnTxt") : `Submit`}
                  </>
                )}
              </Button>
            </div>
          </div>
          <div>
            {uploadProgress > 0 && (
              <ProgressBar now={uploadProgress} label={`${uploadProgress}%`} />
              // <progress value={uploadProgress} max="100">
              //   {uploadProgress}%
              // </progress>
            )}
          </div>
          {message && <p>{message}</p>}
        </form>
        <div
          className="thankyou"
          style={{ display: props.isHideFrorm ? "block" : "none" }}
        >
          <span className="success-icon">
            <i class="fa fa-check-circle"></i>
          </span>
          <h2>Thank You</h2>
          <p className="success-msg">Your form is submitted successfully!</p>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default OnboardingModel;
