import React, { useEffect, useState } from "react";
import { LANGUAGES } from "../constants/languages";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

const TopHeader = ({ handleSkipMainContent, toggleTheme, istoggleTheme }) => {
  const [increaseFont, setIncreaseFont] = useState();
  const [decreaseFont, setDecreaseFont] = useState();
  const [lang, setLang] = useState("en");
  const { i18n } = useTranslation();

  const handleTrans = (event) => {
    let selectedLanguage = event.target.value;
    localStorage.setItem("language", JSON.stringify(selectedLanguage));
    i18n.changeLanguage(selectedLanguage);
  };

  useEffect(() => {
    const langPreference = JSON.parse(localStorage.getItem("language"));
    if (langPreference && langPreference !== undefined) {
      setLang(langPreference);
    } else {
      setLang("en");
    }
  }, [lang]);

  const root = getComputedStyle(document.documentElement);
  const items = JSON.parse(localStorage.getItem("fontSizeVal"));
  useEffect(() => {
    const initialFontSize = JSON.stringify(
      root.getPropertyValue("--base-font-size")
    );
    if (items && items !== undefined) {
      document.documentElement.style.setProperty("--base-font-size", items);
    } else {
      localStorage.setItem("fontSizeVal", initialFontSize);
    }
  }, [root, items]);

  useEffect(() => {
    if (increaseFont) {
      localStorage.setItem("fontSizeVal", increaseFont);
      let getFontSize = JSON.parse(localStorage.getItem("fontSizeVal"));
      document.documentElement.style.setProperty(
        "--base-font-size",
        getFontSize
      );
    }
  }, [increaseFont]);

  useEffect(() => {
    if (decreaseFont) {
      localStorage.setItem("fontSizeVal", decreaseFont);
      let getFontSize = JSON.parse(localStorage.getItem("fontSizeVal"));
      document.documentElement.style.setProperty(
        "--base-font-size",
        getFontSize
      );
    }
  }, [decreaseFont]);

  const increaseSize = () => {
    let oldpx = Number.parseInt(
      root.getPropertyValue("--base-font-size").replaceAll("px")
    );
    let increaseFontSizeVal = JSON.stringify(oldpx + 1 + "px");
    setIncreaseFont(increaseFontSizeVal);
  };
  const decreaseSize = () => {
    let oldpx = Number.parseInt(
      root.getPropertyValue("--base-font-size").replaceAll("px")
    );
    let decreaseFontSizeVal = JSON.stringify(oldpx - 1 + "px");
    setDecreaseFont(decreaseFontSizeVal);
  };
  const resetFontSize = () => {
    localStorage.setItem("fontSizeVal", JSON.stringify("10px"));
    let getFontSize = JSON.parse(localStorage.getItem("fontSizeVal"));
    document.documentElement.style.setProperty("--base-font-size", getFontSize);
  };

  return (
    <section className="topHeader">
      <div className="left">
        <span>भारत सरकार</span>
        <span>|</span>
        <span>Government of India</span>
      </div>
      <div className="right">
        <span onClick={handleSkipMainContent} className="skiptomain">
          {/* <span>
            <img src="../assets/images/topArw.png" alt="skip to main" />
          </span> */}
          <span>
            {" "}
            {i18n.exists("SkipMainContent") ? t("SkipMainContent") : `Skip to main content`}
          </span>
        </span>
        <span className="skiptomain">|</span>
        <span onClick={decreaseSize}>
          <span>A-</span>
        </span>
        <span>|</span>
        <span onClick={resetFontSize}>
          <span>A</span>
        </span>
        <span>|</span>
        <span onClick={increaseSize}>
          <span>A+</span>
        </span>
        <span>|</span>
        <span onClick={toggleTheme}>
          <span
            style={{
              color: istoggleTheme === "light-theme" ? "yellow" : "black",
              backgroundColor:
                istoggleTheme === "light-theme" ? "black" : "white",
              padding:
                istoggleTheme === "light-theme"
                  ? "2px 4px 2px 4px"
                  : "2px 4px 2px 4px",
            }}
          >
            A
          </span>
        </span>
        <select defaultValue="es" onChange={handleTrans}>
          {LANGUAGES.map(({ code, label }) => (
            <option selected={code === lang} key={code} value={code}>
              {label}
            </option>
          ))}
        </select>
      </div>
    </section>
  );
};

export default TopHeader;
