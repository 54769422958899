import React from "react";

const Footer = ({ istoggleTheme }) => {
  return (
    <footer className="footer">
      <img
        className="img-fluid"
        src={`./assets/images/${
          istoggleTheme === "light-theme"
            ? "poweredby.png"
            : "poweredby-white.png"
        }`}
        alt="powered by"
      />
    </footer>
  );
};

export default Footer;
