import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import OnboardingModel from "./UI/OnboardingModel";

const VideosGallary = () => {
  const { t, i18n } = useTranslation();
  const [modalShow, setModalShow] = useState(false);
  const [isHideFrorm, setIsHideFrorm] = useState(false);
  const handleClick = () => {
    window.open("https://mgramseva.digit.org", "_blank");
  };
  useEffect(() => {
    setIsHideFrorm(setIsHideFrorm);
  }, [isHideFrorm]);

  return (
    <>
      <div className="onBoarding mb-5 mb-sm-5 mb-md-5 mb-lg-0 ">
        <h4>
          {i18n.exists("OnBoardingTitle")
            ? t("OnBoardingTitle")
            : `Onboarding “Nal Jal Seva`}
        </h4>

        <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
          <div className="onBoardBtn my-4 mb-5" onClick={handleClick}>
            <span className="btn btn-primary btn-lg btn-doc">
              <img
                className="docImg img-fluid"
                src="./assets/images/prod.svg"
                alt="prod"
              />
              <img
                className="docImg-hover img-fluid"
                src="./assets/images/prod-hover.svg"
                alt="prod"
              />
              {i18n.exists("productDocumentTxt")
                ? t("productDocumentTxt")
                : `Product Documents`}
            </span>
          </div>
        </div>
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center"
          onClick={() => setModalShow(true)}
        >
          <div className="onBoardBtn">
            <span className="btn btn-primary btn-lg btn-doc">
              <img
                className="onbdImg img-fluid"
                src="./assets/images/onbd.svg"
                alt="onbd"
              />
              <img
                className="onbdImg-hover img-fluid"
                src="./assets/images/onbd-hover.svg"
                alt="onbd"
              />
              {i18n.exists("OnBoardingProcesstxt")
                ? t("OnBoardingProcesstxt")
                : `Onboarding Process`}
            </span>
          </div>
        </div>
      </div>
      <OnboardingModel
        key={isHideFrorm ? "hidden" : "visible"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
          setIsHideFrorm(false);
        }}
        isHideFrormHandler={(value) => {
          setIsHideFrorm(value);
        }}
        isHideFrorm={isHideFrorm}
      />
    </>
  );
};

export default VideosGallary;
