import React from "react";
import { useTranslation } from "react-i18next";

function Header({ istoggleTheme }) {
  const { t, i18n } = useTranslation();
  return (
    <>
      <header className="header">
        <div className="logo">
          <img
            className="img-fluid nationalEmblem"
            src={`./assets/images/${
              istoggleTheme === "light-theme"
                ? "national-emblem-india.png"
                : "national-emblem-india.png"
            }`}
            alt="national emblem"
          />
          <img
            className="img-fluid logoLine"
            src="./assets/images/logoline.png"
            alt="line"
          />
          <img
            className="img-fluid naljalSewa jjm-logo"
            src="./assets/images/jjm-logo.png"
            alt="Nal Jal Sewa"
          />
          <div className="newlogo">
            <strong>Nal Jal Seva </strong>
            <span> Department of Drinking </span>
            <span>Water & Sanitation </span>
            <span>Ministry of Jalshakti </span>
          </div>
        </div>
        <div className="jjmTxt">
          <h2>
            {i18n.exists("headlineTag")
              ? t("headlineTag")
              : `Nal Jal Seva Portal`}
          </h2>
          <p>
            {i18n.exists("subTitle")
              ? t("subTitle")
              : ` Operation &amp; Maintenance of rural water supply schemes`}
          </p>
        </div>
        <div className="swachhBharat">
          <img
            src={`./assets/images/${
              istoggleTheme === "light-theme"
                ? "swachh-bharat.png"
                : "swachh-bharat-white.png"
            }`}
            className="swachhBharatLogo"
            alt="swachh bharat"
          />
        </div>
      </header>
      <header className="headerMob">
        <div className="logo">
          {/* <img
            className="img-fluid nationalEmblem"
            src="./assets/images/national-emblem-india.png"
            alt="national emblem"
          /> */}
          {/* <img
            className="img-fluid naljalSewa"
            src="./assets/images/logo.png"
            alt="Nal Jal Sewa"
          /> */}
          <img
            className="img-fluid naljalSewa jjm-logo"
            src="./assets/images/jjm-logo.png"
            alt="Nal Jal Sewa"
          />
          <div className="newlogo">
            <strong>Nal Jal Seva </strong>
            <span> Department of Drinking </span>
            <span>Water & Sanitation </span>
            <span>Ministry of Jalshakti </span>
          </div>
          <img
            className="img-fluid swachhBharatLogo"
            src={`./assets/images/${
              istoggleTheme === "light-theme"
                ? "swachh-bharat.png"
                : "swachh-bharat-white.png"
            }`}
            alt="swachh bharat"
          />
        </div>
        <div className="jjmTxt">
          <h2>Jal Jeevan Mission – Nal Jal Seva</h2>
          <p>Operation &amp; Maintenance of rural water supply schemes</p>
        </div>
      </header>
    </>
  );
}

export default Header;
