import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const VideosGallary = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <div className="videoGallary my-4">
      <h5>
        {i18n.exists("videoGallery") ? t("videoGallery") : `Videos Gallery`}{" "}
      </h5>
      <div className="video">
        <img
          className="videoImg img-fluid"
          src="./assets/images/videoGallary-Img.jpg"
          alt=""
          onClick={handleShow}
        />
        <img
          className="ytPlayIcon img-fluid"
          src="./assets/images/ytPlay.png"
          alt=""
          onClick={handleShow}
        />
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {" "}
            {i18n.exists("videoModalTitle")
              ? t("videoModalTitle")
              : `Jal Jeevan Mission`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <video width="100%" height="315" controls muted>
            <source
              src={"./assets/video/Har Ghar Jal - Jal Jeevan Mission.mp4"}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VideosGallary;
