import React from "react";

const BacktoTop = ({ scrollToTop }) => {
  return (
    <span className="scroll-to-top" onClick={scrollToTop}>
      <img
        src="../assets/images/backtotop.png"
        width={22}
        alt="scroll to top"
      />
    </span>
  );
};

export default BacktoTop;
