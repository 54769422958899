import React, { useEffect, useRef, useState } from "react";
import Header from "../components/Header";
import Banner from "../components/Slider";
import Footer from "../components/Footer";
import NewsEvents from "../components/NewsEvents";
import VideosGallary from "../components/VideosGallary";
import TopHeader from "../components/TopHeader";
import { useTranslation } from "react-i18next";
import BacktoTop from "../components/BacktoTop";

const AboutNalJalSeva = React.lazy(() =>
  import("../components/AboutNalJalSeva")
);
const StatesList = React.lazy(() => import("../components/StatesList"));

const Home = () => {
  const theme = JSON.parse(localStorage.getItem("theme"));
  const [displayScrollToTop, setDisplayScrollToTop] = useState("false");
  const [istoggleTheme, setIstoggleTheme] = useState(
    theme && theme !== "light-theme" ? "dark-theme" : "light-theme"
  );
  const { i18n } = useTranslation();
  const ref = useRef(null);

  //language preference
  useEffect(() => {
    const langPreference = JSON.parse(localStorage.getItem("language"));
    if (langPreference && langPreference !== undefined) {
      i18n.changeLanguage(langPreference); // set prefered language globally
    } else {
      localStorage.setItem("language", JSON.stringify("en")); //set default language to local Storage
    }
  }, [i18n]);

  //toogle Theme
  useEffect(() => {
    //const theme = JSON.parse(localStorage.getItem("theme"));
    //console.log("theme: " + theme);
    if (istoggleTheme && istoggleTheme !== undefined) {
      //console.log("first time theme", theme);
      if (istoggleTheme === "light-theme") {
        document.body.className = "light-theme";
      } else if (istoggleTheme === "dark-theme") {
        document.body.className = "dark-theme";
      } else {
        document.body.className = "light-theme";
      }
      //document.body.className = theme;
    } else {
      // console.log("else!!!!")
      document.body.className = "light-theme";
      localStorage.setItem("theme", JSON.stringify("light-theme")); //set default language to local Storage
    }
  }, [istoggleTheme]);

  //for display block and none of the other scroll to top div
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 100) {
        setDisplayScrollToTop(true);
      } else {
        setDisplayScrollToTop(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [displayScrollToTop]);

  //handle skip to main content function
  const handleSkipMainContent = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  //handle skip to main content function
  const toggleTheme = () => {
    if (istoggleTheme === "dark-theme") {
      localStorage.setItem("theme", JSON.stringify("light-theme"));
      setIstoggleTheme("light-theme");
    } else {
      localStorage.setItem("theme", JSON.stringify("dark-theme"));
      setIstoggleTheme("dark-theme");
    }
  };

  //handle scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="main_wrapper">
      <TopHeader
        handleSkipMainContent={handleSkipMainContent}
        toggleTheme={toggleTheme}
        istoggleTheme={istoggleTheme}
      />
      <div className="container-fluid">
        <Header istoggleTheme={istoggleTheme} />
        <div className="row">
          <div className="col-lg-8 col-md-7 col-sm-12 order-lg-1 order-md-1 order-sm-2 order-2">
            <Banner />
            <section className="aboutUs" ref={ref}>
              <div className="row">
                <AboutNalJalSeva />
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <NewsEvents />
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <VideosGallary />
                </div>
              </div>
            </section>
          </div>
          <div className="col-lg-4 col-md-5 col-sm-12 order-lg-2 order-md-2 order-sm-1 order-1">
            <StatesList istoggleTheme={istoggleTheme} />
          </div>
        </div>
      </div>
      <div className="container-fluid footer-container">
        <Footer istoggleTheme={istoggleTheme} />
      </div>
      {displayScrollToTop ? <BacktoTop scrollToTop={scrollToTop} /> : null}
    </div>
  );
};

export default Home;
